import { useEffect, useRef, useState } from 'react'
import { BoxProps, chakra } from '@chakra-ui/react'

import { MotionBox, MotionIcon } from 'components/Motion'

import useSafariCheck from 'hooks/useSafariCheck'

interface AnimatedCircleAltProps {
  className?: string
  strokeWidth?: string
}

export type AnimatedCircleAltType = AnimatedCircleAltProps & BoxProps

const AnimatedCircleAlt = ({ className, strokeWidth, ...props }: AnimatedCircleAltProps) => {
  const ref = useRef<SVGCircleElement>()

  const [circleLength, setCircleLength] = useState(0)

  const isSafari = useSafariCheck()

  useEffect(() => {
    if (ref?.current) {
      setCircleLength(Math.ceil(ref.current.getTotalLength()) + 1)
    }
  }, [])

  return (
    <MotionIcon className={className} viewBox="0 0 50 50" {...props}>
      <MotionBox
        as="circle"
        cx="25"
        cy="25"
        r="24"
        fill="none"
        style={{
          transform: 'rotate(-90deg)',
          transformOrigin: 'center',
          strokeDasharray: circleLength,
        }}
        strokeWidth={strokeWidth || '1'}
        variants={!isSafari && variants}
        custom={circleLength * -2}
        ref={ref}
      />
    </MotionIcon>
  )
}

const variants = {
  initial: {
    strokeDashoffset: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
  visible: {
    strokeDashoffset: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
  hover: (offset: number) => ({
    strokeDashoffset: offset,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  }),
}

export default chakra(AnimatedCircleAlt)
